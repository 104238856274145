<template>
  <v-container class="align-start" :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col>
        <v-select
          v-model="activeStage"
          :items="stages"
          label="Active Stage"
          solo
        ></v-select>
      </v-col>
    </v-row>
    <v-row align="start">
      <v-col md="6" class="d-flex flex-column">
        <v-card class="flex-grow-1">
          <v-toolbar>
            <v-toolbar-title>Polls</v-toolbar-title>
            <v-spacer />
            <v-dialog v-model="newPollDialog" persistent max-width="400">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="green" text v-bind="attrs" v-on="on">
                  <v-icon left>mdi-plus</v-icon>
                  Create New Poll
                </v-btn>
              </template>
              <AdminPollCreate
                :stage="activeStage"
                @close="newPollDialog = false"
              />
            </v-dialog>
          </v-toolbar>
          <div v-if="!polls.length">
            <v-alert type="info" text> POLLS_EMPTY </v-alert>
          </div>
          <div style="max-height: calc(100vh - 200px); overflow-y: scroll;">
            <AdminPollItem
              v-for="poll in polls"
              :key="poll._id"
              :poll="poll"
              :isPresenting="isPresenting(poll)"
            />
          </div>
        </v-card>
      </v-col>
      <v-col md="6" class="d-flex flex-column">
        <v-card class="flex-grow-1">
          <router-view />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<route>
{
  "meta": {
    "admin": true
  }
}
</route>

<script>
import { orderBy, filter, keyBy } from 'lodash';
import AdminPollItem from '@/components/admin/PollItem';
import AdminPollCreate from '@/components/admin/PollCreate';
export default {
  name: 'PollsAdmin',
  components: { AdminPollItem, AdminPollCreate },
  async created() {
    await this.$socket.emit('call', 'polls.getAll', (err, data) => {
      this.pollsRaw = keyBy(data, '_id');
    });
    this.$socket.emit(
      'call',
      'presenter.get',
      { subscribe: true },
      (err, data) => {
        if (data) {
          Object.keys(data).forEach(k => {
            this.$set(this.presenter, k, data[k]);
          });
        }
      }
    );
  },
  data() {
    return {
      stages: [
        { value: 'main', text: 'Ana Salon' },
        { value: 'edu', text: 'Eğitim Salonu' }
      ],
      activeStage: 'main',
      pollsRaw: {},
      newPollDialog: false,
      presenter: {}
    };
  },
  sockets: {
    poll_update(data) {
      this.$set(this.pollsRaw, data._id, data);
    },
    presenter_update(data) {
      if (data) {
        Object.keys(data).forEach(k => {
          this.$set(this.presenter, k, data[k]);
        });
      }
    }
  },
  computed: {
    polls() {
      const filtered = filter(this.pollsRaw, { stage: this.activeStage });
      const ordered = orderBy(filtered, ['createdAt'], ['desc']);
      return ordered;
    }
  },
  async mounted() {},
  beforeDestroy() {},
  methods: {
    isPresenting(poll) {
      return this.presenter[poll.stage]?.id === poll._id;
    }
  },
  head() {
    return {
      title: 'Polls Management'
    };
  }
};
</script>
