<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-title class="pt-8 white--text">
          <h1>
            22nd Innovation Day
          </h1>
        </v-card-title>
        <v-card-text class="white--text">
          *Number of Patent Applications include the years 2020-2021.
        </v-card-text>

        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
            xl="3"
            v-for="p in innovation"
            :key="p.name"
            class="d-flex mb-n5"
          >
            <v-alert
              class="d-flex flex-1"
              icon="mdi-seal"
              prominent
              type="info"
              style="width: 100%; background-color: rgba(249, 0, 46, 0.8) !important"
            >
              <h3 class="">
                {{ p.name }}
              </h3>
              <div class="text-body-2">{{ p.center }}</div>
              <div class="text-body-2">
                {{ p.patents }} Patent Application(s)
              </div>
            </v-alert>
          </v-col>
        </v-row>

        <v-card-title class="pt-8 white--text">
          <h2>
            Design Awards
          </h2>
        </v-card-title>

        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
            xl="3"
            v-for="p in design"
            :key="p.name"
            class="d-flex mb-n5"
          >
            <v-alert
              class="d-flex flex-1"
              icon="mdi-seal"
              prominent
              type="info"
              style="width: 100%; background-color: rgba(249, 0, 46, 0.8) !important"
            >
              <h3 class="">
                {{ p.name }}
              </h3>
              <div class="text-body-2">{{ p.center }}</div>
              <div class="text-body-2">
                {{ p.count }} {{ p.awards }} Award(s)
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { sortBy } from 'lodash';

export default {
  name: 'Booth',
  data() {
    return {
      innovation: sortBy(
        [
          {
            name: 'SELAHATTİN ALİ MELİKÖYLÜ',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'ERTAN BİLİR',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'CHIU GOODSPEED',
            center: 'China R&D Center',
            patents: 4
          },
          {
            name: 'EREN YOLAÇAN',
            center: 'Electronics R&D Center',
            patents: 2
          },
          {
            name: 'HAZAL ER',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'CAN SAR',
            center: 'Dryer R&D Center',
            patents: 4
          },
          {
            name: 'YUNUS EKREM TEKEŞ',
            center: 'Dryer R&D Center',
            patents: 5
          },
          {
            name: 'AYKUN AÇIL',
            center: 'Dryer R&D Center',
            patents: 5
          },
          {
            name: 'İLHAN ÇALIŞKAN',
            center: 'Dryer R&D Center',
            patents: 3
          },
          {
            name: 'CEM YONAR',
            center: 'Dryer R&D Center',
            patents: 4
          },
          {
            name: 'BURHAN ŞAHİN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 7
          },
          {
            name: 'FARUK YILDIZ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'İBRAHİM CANİGÜR',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 7
          },
          {
            name: 'YUSUF KOÇ',
            center: 'Central R&D',
            patents: 11
          },
          {
            name: 'ZERRİN YILMAZ',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'OZAN ATALAY',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'AYŞE KIZILDEMİR',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'MURAT ELGÜN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ONUR YENER',
            center: 'Dryer R&D Center',
            patents: 1
          },
          {
            name: 'FARUK SEVEN',
            center: 'Dryer R&D Center',
            patents: 7
          },
          {
            name: 'ONUR MUHİDDİN',
            center: 'Dryer R&D Center',
            patents: 9
          },
          {
            name: 'FURKAN KIBRIS',
            center: 'Dryer R&D Center',
            patents: 7
          },
          {
            name: 'ENGİN CEYLAN',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'MUHAMMET ALİ İSTEK',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'METİN ELİTAŞ',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'İSMAİL KARATEPE',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'FURKAN CÖMERT',
            center: 'Electronics R&D Center',
            patents: 2
          },
          {
            name: 'İBRAHİM DEMİREL',
            center: 'Electronics R&D Center',
            patents: 3
          },
          {
            name: 'BİLGİN HACIOĞLU',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'SITKI BİÇER',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'MELİH ÖZDİLEK',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'HAKAN ALTUNTAŞ',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'MURAT KANTAŞ',
            center: 'Central R&D',
            patents: 7
          },
          {
            name: 'SONAT YAYLA',
            center: 'Central R&D',
            patents: 8
          },
          {
            name: 'OKAN AYDINER',
            center: 'Cooking Appliances R&D Center',
            patents: 2
          },
          {
            name: 'EMRAH BİÇER',
            center: 'Cooking Appliances R&D Center',
            patents: 7
          },
          {
            name: 'MUSTAFA ÖNAL',
            center: 'Cooking Appliances R&D Center',
            patents: 3
          },
          {
            name: 'SERVET TURGUT',
            center: 'Cooking Appliances R&D Center',
            patents: 2
          },
          {
            name: 'İSMAİL DEMİRTAŞ',
            center: 'Cooking Appliances R&D Center',
            patents: 3
          },
          {
            name: 'YALÇIN KUM',
            center: 'Cooking Appliances R&D Center',
            patents: 4
          },
          {
            name: 'OSMAN CENGİZ',
            center: 'Cooking Appliances R&D Center',
            patents: 6
          },
          {
            name: 'FATİH SABAH',
            center: 'Cooking Appliances R&D Center',
            patents: 4
          },
          {
            name: 'EROL KARAER',
            center: 'Cooking Appliances R&D Center',
            patents: 3
          },
          {
            name: 'ENİS TURGUT YALÇINKAYA',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'YASİN SEDAT YIRMILI',
            center: 'Cooking Appliances R&D Center',
            patents: 2
          },
          {
            name: 'CEREN KOVANCI',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'ORKUN KAYMAKCI',
            center: 'Central R&D',
            patents: 4
          },
          {
            name: 'MUSTAFA SEZER',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'ERGİN ARSLAN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'İBRAHİM HALİL DOĞRUYOL',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'SARPER MARAŞLI',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 4
          },
          {
            name: 'YASİN BATUR',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'CİHAN GÜNDÜZ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'ALPER YEŞİLÇUBUK',
            center: 'Central R&D',
            patents: 11
          },
          {
            name: 'BERRİN GÜLBAY',
            center: 'Central R&D',
            patents: 8
          },
          {
            name: 'RESUL KARTAL',
            center: 'Central R&D',
            patents: 12
          },
          {
            name: 'SİMGE TARKUÇ',
            center: 'Central R&D',
            patents: 9
          },
          {
            name: 'AHMET BURAK TOP',
            center: 'Washing Machine R&D Center',
            patents: 8
          },
          {
            name: 'CAN ARER',
            center: 'Product Sourcing R&D Center',
            patents: 1
          },
          {
            name: 'AHU SÜRMEN',
            center: 'Product Sourcing R&D Center',
            patents: 1
          },
          {
            name: 'TAYFUN BALIK',
            center: 'Product Sourcing R&D Center',
            patents: 1
          },
          {
            name: 'GÖKHAN TAYLAN',
            center: 'Product Sourcing R&D Center',
            patents: 1
          },
          {
            name: 'ÖNER SEVEN',
            center: 'Product Sourcing R&D Center',
            patents: 1
          },
          {
            name: 'HASRET GÜRSOY KÜLAHCI',
            center: 'Dishwasher R&D Center',
            patents: 4
          },
          {
            name: 'MEHMET DİKSAN',
            center: 'Dishwasher R&D Center',
            patents: 3
          },
          {
            name: 'BUĞRA ÇETİNKAYA',
            center: 'Washing Machine R&D Center',
            patents: 6
          },
          {
            name: 'ÇAĞDAŞ YALÇIN',
            center: 'Washing Machine R&D Center',
            patents: 8
          },
          {
            name: 'ERGİN METİN',
            center: 'Washing Machine R&D Center',
            patents: 4
          },
          {
            name: 'CANER GERMİYANLIOĞLU',
            center: 'Washing Machine R&D Center',
            patents: 1
          },
          {
            name: 'KAĞAN ÖZERK',
            center: 'Washing Machine R&D Center',
            patents: 2
          },
          {
            name: 'YASİN BALLI',
            center: 'Washing Machine R&D Center',
            patents: 3
          },
          {
            name: 'YAKUP AKTAŞ',
            center: 'Washing Machine R&D Center',
            patents: 1
          },
          {
            name: 'UFUK AŞKIN',
            center: 'Washing Machine R&D Center',
            patents: 2
          },
          {
            name: 'MEHMET YAŞAR',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 4
          },
          {
            name: 'HÜSEYİN GÜMÜŞ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'KUBİLAY BORA',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'ÖNER TOY',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'RECEP KARAGÖZ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'ERCAN AKYÜZ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'İBRAHİM YILMAZ EVREN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'MEHMET ERCAN KAYMAK',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'GİZEM ERDAL',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 5
          },
          {
            name: 'RESUL YAVUZ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'ENES İNANÇ',
            center: 'Central R&D',
            patents: 5
          },
          {
            name: 'CEM AVCI',
            center: 'Central R&D',
            patents: 5
          },
          {
            name: 'AYLİN MET',
            center: 'Central R&D',
            patents: 7
          },
          {
            name: 'NİHAL YILMAZ',
            center: 'Central R&D',
            patents: 9
          },
          {
            name: 'İHSAN OZAN YILDIRIM',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'FURKAN DENİZER',
            center: 'Product Sourcing R&D Center',
            patents: 2
          },
          {
            name: 'ALPER YAĞCI',
            center: 'Product Sourcing R&D Center',
            patents: 1
          },
          {
            name: 'ENSAR GÜRER',
            center: 'Product Sourcing R&D Center',
            patents: 2
          },
          {
            name: 'FATMA NUR ÇİMEN',
            center: 'Product Sourcing R&D Center',
            patents: 1
          },
          {
            name: 'NİLÜFER DERİN KEÇECİ',
            center: 'Product Sourcing R&D Center',
            patents: 1
          },
          {
            name: 'FİRDEVS BAŞARAN ERTAŞ',
            center: 'Dryer R&D Center',
            patents: 2
          },
          {
            name: 'İSMAİL ŞENGÜN',
            center: 'Dryer R&D Center',
            patents: 3
          },
          {
            name: 'ERSİN BEDİR',
            center: 'Dryer R&D Center',
            patents: 4
          },
          {
            name: 'Ammar Naseem Khan',
            center: 'Cooking Appliances R&D Center',
            patents: 2
          },
          {
            name: 'METİN ÖZCAN',
            center: 'Cooking Appliances R&D Center',
            patents: 2
          },
          {
            name: 'MUTLU MEDİN',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'MUSTAFA CENGİZ',
            center: 'Dryer R&D Center',
            patents: 1
          },
          {
            name: 'MUTLU TUNA',
            center: 'Dryer R&D Center',
            patents: 2
          },
          {
            name: 'ALEV ELİKALFA KÖKSAL',
            center: 'Dishwasher R&D Center',
            patents: 5
          },
          {
            name: 'ÇAGATAY AKÇA',
            center: 'Arcelik Design Center',
            patents: 1
          },
          {
            name: 'ALPER BAŞARAN',
            center: 'Dishwasher R&D Center',
            patents: 2
          },
          {
            name: 'NASIR EFE ARAS',
            center: 'Dishwasher R&D Center',
            patents: 8
          },
          {
            name: 'ÖMER BURAK ÇOBAN',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'MURAT KOŞAR',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'NEVİN AYATA AKKAŞ',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'FURKAN BAYAV',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ALPER BALKAN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'GÜNAY KARDAŞ',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'MEHMET BARIŞ GÖÇER',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ERGİN TAŞDELEN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 4
          },
          {
            name: 'SEMİH GÜREL',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 3
          },
          {
            name: 'DAVUT AYHAN ŞERABATIR',
            center: 'Cooking Appliances R&D Center',
            patents: 2
          },
          {
            name: 'KADİR USTA',
            center: 'Cooking Appliances R&D Center',
            patents: 5
          },
          {
            name: 'VEYSEL ERTAN ÇETİNKAYA',
            center: 'Cooking Appliances R&D Center',
            patents: 2
          },
          {
            name: 'AYŞE ACAR',
            center: 'Cooking Appliances R&D Center',
            patents: 2
          },
          {
            name: 'MUHAMMED SEZGİN ÖZER',
            center: 'Dryer R&D Center',
            patents: 1
          },
          {
            name: 'BORA TARİMTORU',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'KADİR RIDVAN ÇELİK',
            center: 'Cooking Appliances R&D Center',
            patents: 4
          },
          {
            name: 'MURAT ÜNGÖR',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 5
          },
          {
            name: 'BEYTULLAH GÖRDES',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 3
          },
          {
            name: 'HAKAN KARADENİZ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'HAMDİ GÜNDOĞAN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 10
          },
          {
            name: 'SERDAR KANTIK',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 10
          },
          {
            name: 'GÜVEN ERTAŞ',
            center: 'Dryer R&D Center',
            patents: 2
          },
          {
            name: 'SADULLAH UĞUR',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'MELİH ÖZDOĞAN',
            center: 'Cooking Appliances R&D Center',
            patents: 4
          },
          {
            name: 'İSMET BAYHAN',
            center: 'Washing Machine R&D Center',
            patents: 2
          },
          {
            name: 'SERVET ÇATALTEPE',
            center: 'Washing Machine R&D Center',
            patents: 2
          },
          {
            name: 'TUĞÇE ÖNER',
            center: 'Central R&D',
            patents: 5
          },
          {
            name: 'BESTE PAMUKOĞULLARI',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'CEYDA BAŞARAN',
            center: 'Washing Machine R&D Center',
            patents: 1
          },
          {
            name: 'VAROL DİNDORUK',
            center: 'Washing Machine R&D Center',
            patents: 2
          },
          {
            name: 'EMRAH MUSTAFA HOŞCOŞKUN',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'MUSTAFA KÜÇÜKKURU',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'ÖZGÜR ÖZEN',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'GİZEM MISIRLI',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'SELİN HAZNEDAROĞLU',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'GİZEM DİRİL',
            center: 'Electronics R&D Center',
            patents: 2
          },
          {
            name: 'ANIL ÜZUMCÜOĞLU',
            center: 'Electronics R&D Center',
            patents: 2
          },
          {
            name: 'DİLEK KAYAHAN',
            center: 'Electronics R&D Center',
            patents: 2
          },
          {
            name: 'FURKAN TUNÇEL',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'ERSİN DÖNMEZ',
            center: 'Central R&D',
            patents: 5
          },
          {
            name: 'EREN MUTLU',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'MUTLU İPEK',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'MEHMET KAYA',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'FATİH KASAP',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'FİLİZ ÇELİK',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'GÖKHAN AK',
            center: 'Dishwasher R&D Center',
            patents: 6
          },
          {
            name: 'TANER KAYHAN',
            center: 'Dishwasher R&D Center',
            patents: 1
          },
          {
            name: 'NURGÜL GÖKDERE',
            center: 'Dishwasher R&D Center',
            patents: 4
          },
          {
            name: 'GÜVENÇ NAYMAN NUMANOĞLU',
            center: 'Dishwasher R&D Center',
            patents: 1
          },
          {
            name: 'CAN UĞURELLİ',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'GİZEM CURDANELİ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'HALİSE OSTÜNDAĞ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'AYSUN ÇALKAYA',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 3
          },
          {
            name: 'MERT BULMUŞ',
            center: 'Washing Machine R&D Center',
            patents: 1
          },
          {
            name: 'YUNUS EMRE AYDOĞDU',
            center: 'Washing Machine R&D Center',
            patents: 3
          },
          {
            name: 'CAHİT ATAGÜN',
            center: 'Washing Machine R&D Center',
            patents: 3
          },
          {
            name: 'SEÇİL BAYDEMİR',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'TUBA ÇETİN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'ALİ ERGÜN',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'ARDA DÖNERKAYALI',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'GRAHAM ANDERSON',
            center: 'Cambridge R&D Center',
            patents: 2
          },
          {
            name: 'ADAM ROBINSON',
            center: 'Cambridge R&D Center',
            patents: 1
          },
          {
            name: 'EMRE HAS',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'HALİL ELGÜN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'KUTAY EDİS',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'İSA TOSUN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'OZAN ULE',
            center: 'Central R&D',
            patents: 4
          },
          {
            name: 'GÖKHAN GÜRGEN',
            center: 'Dryer R&D Center',
            patents: 1
          },
          {
            name: 'YÜKSEL ONUR',
            center: 'Dryer R&D Center',
            patents: 1
          },
          {
            name: 'FATİH ENES UZUN',
            center: 'Dryer R&D Center',
            patents: 1
          },
          {
            name: 'CANSIN KORKMAZ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 4
          },
          {
            name: 'CİHAT BACAK',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 7
          },
          {
            name: 'EMRE YILDIRIM',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'DENİZ AKTUNA',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'BURAK ŞAHİN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'ELİF OLFAZ HEZER',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'AHMET REFİK ÖZDEMİR',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ERKAN GÖKMEN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'TOLGA NURETTİN AYNUR',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'UĞUR ÜNALAN',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'HAKAN SARIER',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'MURAT TUNÇ',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'ERKAN ÖZTÜRK',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'CAN ÇETİN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ÖZGÜR ÖZKAN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'BERKAY GÖNÜL',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ÇAĞATAY BÜYÜKTOPÇU',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'ONUR ÖZCAN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'DENİZ GÜNDOĞAN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 3
          },
          {
            name: 'MEHMET ERCAN TEKİN',
            center: 'ITU Ari Teknokent R&D Center',
            patents: 2
          },
          {
            name: 'UĞUR HALATOĞLU',
            center: 'ITU Ari Teknokent R&D Center',
            patents: 2
          },
          {
            name: 'ILGAZ ÇAKIN',
            center: 'ITU Ari Teknokent R&D Center',
            patents: 3
          },
          {
            name: 'ADEM AYHAN',
            center: 'Washing Machine R&D Center',
            patents: 1
          },
          {
            name: 'RECEP AYAS',
            center: 'Washing Machine R&D Center',
            patents: 3
          },
          {
            name: 'SEÇKİN DOĞAN',
            center: 'Dryer R&D Center',
            patents: 1
          },
          {
            name: 'HAKAN ÇAKIR',
            center: 'Washing Machine R&D Center',
            patents: 1
          },
          {
            name: 'ORHAN DAİOĞLU',
            center: 'Washing Machine R&D Center',
            patents: 1
          },
          {
            name: 'SENA KIRIK',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'EMRE DEMİRTAŞ',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'FATİH TUTAL',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'TÜMAY CITAK',
            center: 'Dishwasher R&D Center',
            patents: 1
          },
          {
            name: 'ARMİN AMİNDARİ',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'HÜSNÜ KERPİÇÇİ',
            center: 'Central R&D',
            patents: 4
          },
          {
            name: 'ÖMER ARGINHAN',
            center: 'Dishwasher R&D Center',
            patents: 1
          },
          {
            name: 'DANYAL AMIN CHAUDRY',
            center: 'Cooking Appliances R&D Center',
            patents: 4
          },
          {
            name: 'AYDIN ÖĞÜT',
            center: 'Central R&D',
            patents: 5
          },
          {
            name: 'ADEM GÜR',
            center: 'Dishwasher R&D Center',
            patents: 1
          },
          {
            name: 'ORHAN GÖKHAN SOMER',
            center: 'Dishwasher R&D Center',
            patents: 1
          },
          {
            name: 'SERKAN YALÇIN',
            center: 'Dishwasher R&D Center',
            patents: 3
          },
          {
            name: 'SERKAN DEMİR',
            center: 'Dishwasher R&D Center',
            patents: 6
          },
          {
            name: 'TUĞRUL BAŞEŞME',
            center: 'Dishwasher R&D Center',
            patents: 1
          },
          {
            name: 'EMİN ÜNAL',
            center: 'Dishwasher R&D Center',
            patents: 3
          },
          {
            name: 'BURCU ÜNAT',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'BEKİR KIR',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'RECAİ ONAY',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'MURAT YETİLMEZSOY',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'KEMAL DİNÇER',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 6
          },
          {
            name: 'CEM SELÇUK',
            center: 'Dryer R&D Center',
            patents: 1
          },
          {
            name: 'UĞUR IŞIKVEREN',
            center: 'WAT Motor R&D Center',
            patents: 1
          },
          {
            name: 'SERHAT GÜNERİ',
            center: 'WAT Motor R&D Center',
            patents: 1
          },
          {
            name: 'OSMAN GÖKHAN ERSOY',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'EMRE KARAAĞAÇ',
            center: 'Central R&D',
            patents: 8
          },
          {
            name: 'NİHAT GÜNDÜZ',
            center: 'Central R&D',
            patents: 9
          },
          {
            name: 'MİRAÇ AKNAR',
            center: 'Central R&D Center',
            patents: 1
          },
          {
            name: 'ENGİN ELİBOL',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'GİZEM ÜNALER',
            center: 'Arcelik Design Center',
            patents: 1
          },
          {
            name: 'MERT TOSUN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 4
          },
          {
            name: 'YASİN AKSIN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 4
          },
          {
            name: 'VEYSİ ERCAN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'İBRAHİM YANIK',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'ERHAN KAPICI',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'TAYFUN KAYALI',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'SERDAR BİRİCİK',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'İRFAN GÜNAL',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'MESUT BİLİR',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'UMUT YILMAZ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'ARDA ÖZSU',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'EBRU ÇELEBİ',
            center: 'Central R&D',
            patents: 4
          },
          {
            name: 'TANSEL ÖZTÜRK',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'VAROL KAYIŞDAĞ',
            center: 'Central R&D',
            patents: 4
          },
          {
            name: 'ORÇUN YÜCEL',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'SEFA KEMAL MANDAL',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'OSMAN OSMAN',
            center: 'Electronics R&D Center',
            patents: 4
          },
          {
            name: 'ALPER ÖZEL',
            center: 'Electronics R&D Center',
            patents: 5
          },
          {
            name: 'CENGİZ BERKAY',
            center: 'Electronics R&D Center',
            patents: 4
          },
          {
            name: 'BİRKAN DEMİRCAN',
            center: 'Electronics R&D Center',
            patents: 4
          },
          {
            name: 'EMİR GÜLDÜR',
            center: 'Washing Machine R&D Center',
            patents: 5
          },
          {
            name: 'SEMA KARACA',
            center: 'Washing Machine R&D Center',
            patents: 4
          },
          {
            name: 'MEHMET ÖZBEK',
            center: 'Washing Machine R&D Center',
            patents: 2
          },
          {
            name: 'ÖZCAN HATİPOĞLU',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'ABDULLAH SERT',
            center: 'Central R&D',
            patents: 5
          },
          {
            name: 'FUNDA ERDEM',
            center: 'Central R&D',
            patents: 4
          },
          {
            name: 'MEHMET MARAŞLI',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'HALUK KARATAŞ',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ESRA TÜZEL',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'ERKAN TARAKÇI',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ÖZKAN SORKUN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'SERHAT ŞENGÜN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'SEZGİN BİLGİLİ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'BAHA BARIŞ ERCAN',
            center: 'Central R&D Center',
            patents: 2
          },
          {
            name: 'OGUZ KEREM ŞENGÖZ',
            center: 'Central R&D Center',
            patents: 2
          },
          {
            name: 'AHMET ALDAĞ',
            center: 'Central R&D Center',
            patents: 2
          },
          {
            name: 'TUĞBA YALÇIN',
            center: 'Central R&D Center',
            patents: 1
          },
          {
            name: 'DİLAN ER',
            center: 'Central R&D',
            patents: 6
          },
          {
            name: 'NİYAZİ SERDAR SARIÇİFTÇİ',
            center: 'Central R&D',
            patents: 5
          },
          {
            name: 'PINAR YAVUZ',
            center: 'Central R&D',
            patents: 9
          },
          {
            name: 'TUĞÇE YILMAZ',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'KÜRŞAD DAYI',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'MUMUN AKSAKAL',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'TEVFİK ÖRKÜN',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'CİHAN ŞENYÜZ',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'YUNUS EMRE BULUT',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'SİNAN KAYA',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'İSMET ARSAN',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'NİHAT KANDEMİR',
            center: 'Central R&D',
            patents: 9
          },
          {
            name: 'ONUR ERCAN',
            center: 'Dryer R&D Center',
            patents: 4
          },
          {
            name: 'ERDİNÇ DURAK',
            center: 'Dryer R&D Center',
            patents: 2
          },
          {
            name: 'CENKAY ZADEOĞLU',
            center: 'Dryer R&D Center',
            patents: 1
          },
          {
            name: 'SERGEN ERKAN ARSLAN',
            center: 'Dryer R&D Center',
            patents: 3
          },
          {
            name: 'MEHMET EMİN ÇEPNİ',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'ÖNDER BALİOĞLU',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'AYKUT EFE',
            center: 'Central R&D Center',
            patents: 1
          },
          {
            name: 'ONUR POYRAZ',
            center: 'Central R&D',
            patents: 7
          },
          {
            name: 'UMUT MORDOĞAN',
            center: 'Central R&D',
            patents: 7
          },
          {
            name: 'İLHAN BALIKÇI',
            center: 'Central R&D',
            patents: 7
          },
          {
            name: 'BURAK ÇOBAN',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'LEVENT AKDAĞ',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ÜNSAL KAYA',
            center: 'Central R&D',
            patents: 6
          },
          {
            name: 'ENDER AKYÜZ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'HASAN ALİ ÜNLÜ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'OKTAY AYGÜN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'AHMET DEMİRTAŞ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'GÖKHAN ÖZŞEKER',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'TEKİN ASLAN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'TAMER TOMBULOĞLU',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'BEYZA ALKAN',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'KORAY ERDOĞAN',
            center: 'Central R&D',
            patents: 6
          },
          {
            name: 'GÖKMEN PEKER',
            center: 'Central R&D',
            patents: 5
          },
          {
            name: 'VASİ KADİR ERTİŞ',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'METEHAN CİHANGİR',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'GÜRKAN KOÇSIZ',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'HÜSAMETTİN ÇELİK',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'BURAK KOCA',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'ŞENOL CAVUŞOĞLU',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'BAŞAK ARSLAN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'AHMET YASIN KARABAY',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ILKE BARIS OZSUT',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ERCAN KURTULDU',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'SEREN ATAKURU',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 3
          },
          {
            name: 'EMRE AKYILDIZ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 4
          },
          {
            name: 'AHMET FERİT COŞAN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 4
          },
          {
            name: 'GÖKHAN GÖL',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 3
          },
          {
            name: 'SAMET AK',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'MERYEM ATİLA',
            center: 'Dryer R&D Center',
            patents: 2
          },
          {
            name: 'SERCAN SINIRLIOGLU',
            center: 'Central R&D',
            patents: 5
          },
          {
            name: 'CANBERK SEZER',
            center: 'Central R&D',
            patents: 4
          },
          {
            name: 'ÖZGÜR ÖZTÜRK',
            center: 'Dishwasher R&D Center',
            patents: 1
          },
          {
            name: 'SELÇUK YILDIRIM',
            center: 'Dishwasher R&D Center',
            patents: 1
          },
          {
            name: 'HAMİT TAYLAN YÜCE',
            center: 'Dishwasher R&D Center',
            patents: 2
          },
          {
            name: 'KADİR CAN ATICI',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'AKİF HAKKI POLAT',
            center: 'Central R&D',
            patents: 5
          },
          {
            name: 'MELİH İLKAY KANAT',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'KAAN ÇENESİZ',
            center: 'Central R&D',
            patents: 6
          },
          {
            name: 'TÜRKER ŞENTÜRK',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'EMRE POLAT',
            center: 'Product Sourcing R&D Center',
            patents: 1
          },
          {
            name: 'UĞUR SARITAŞ',
            center: 'Product Sourcing R&D Center',
            patents: 1
          },
          {
            name: 'DEHA GÜLŞEN',
            center: 'Product Sourcing R&D Center',
            patents: 1
          },
          {
            name: 'ORHAN ATABEY',
            center: 'Dishwasher R&D Center',
            patents: 2
          },
          {
            name: 'UĞUR KAN',
            center: 'Dishwasher R&D Center',
            patents: 6
          },
          {
            name: 'DENİZ DUMAN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'HALİL İBRAHİM ALTINTAŞ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'ÇETİN USLU',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'TAYFUN İNAL',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'NİHAT DURAN',
            center: 'Arcelik Design Center',
            patents: 1
          },
          {
            name: 'MUHAMMET RAMOĞLU',
            center: 'Arcelik Design Center',
            patents: 1
          },
          {
            name: 'CAN ONUR VANCI',
            center: 'Arcelik Design Center',
            patents: 1
          },
          {
            name: 'OĞUZHAN KAYA',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ARDA GÖRKEM KALENDER',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'BANU ARAR',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'CENK UYAR',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'GÖKHAN SIR',
            center: 'Central R&D',
            patents: 5
          },
          {
            name: 'EMRE GEZE',
            center: 'Dryer R&D Center',
            patents: 2
          },
          {
            name: 'MUHAMMED ERGUN',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'SALİH GÖK',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'İLHAN OFLUGİL',
            center: 'Cooking Appliances R&D Center',
            patents: 3
          },
          {
            name: 'TEVFİK EKER',
            center: 'Cooking Appliances R&D Center',
            patents: 2
          },
          {
            name: 'DİDEM POLAT',
            center: 'Dishwasher R&D Center',
            patents: 2
          },
          {
            name: 'BARIŞ YENER',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'SEZER ASLAN',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'ULAŞ OKTAY',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'CEYHAN IŞIKLI',
            center: 'Dryer R&D Center',
            patents: 1
          },
          {
            name: 'DOĞAN TAŞTAN',
            center: 'Dryer R&D Center',
            patents: 1
          },
          {
            name: 'GÜLSEREN ASLI GÖK',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'CAHiT CAN ÇANAKÇI',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'ERDAL PİLAV',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'GİZEM SEMRA ARITÜRK',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'TUĞBA ÇETİNTÜRK',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'SATI MUTLU BOZAY',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'AYLA KURAN',
            center: 'Dishwasher R&D Center',
            patents: 3
          },
          {
            name: 'MEHMET OZAN TRAŞ',
            center: 'Dishwasher R&D Center',
            patents: 1
          },
          {
            name: 'ÇAGLAR KOÇ',
            center: 'Dishwasher R&D Center',
            patents: 1
          },
          {
            name: 'AYTEKİN ONAR',
            center: 'Dishwasher R&D Center',
            patents: 2
          },
          {
            name: 'ERKAN KARAKAYA',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'MERT CAN TAŞKIN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'TAYFUN ATSIZ',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ERHAN ÖNDER',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'IŞIL YILDIZ',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'BURCU ULUDAĞ',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'HÜSEYİN MERT ÇALICIOĞLU',
            center: 'Central R&D',
            patents: 4
          },
          {
            name: 'AYŞİN ACIR',
            center: 'Dryer R&D Center',
            patents: 2
          },
          {
            name: 'KÜBRA ÇALIŞIR',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'BÜLENT KALA',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'HALİL ŞEN',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'ONUR ÖNDER',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'OGÜN HIZAR',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'ALPER BİLGİÇ',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'MEHMET ÇAKMAK',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'EDA GÜMÜŞSOY',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'FATİH TÜRKER',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 3
          },
          {
            name: 'KERİM YILDIRIM',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 3
          },
          {
            name: 'AHMET KURT',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'ENGİN BEYTER',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'OĞUZ TAN',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'SEDAT KALIN',
            center: 'Dryer R&D Center',
            patents: 2
          },
          {
            name: 'YÜCEL KURTOĞLU',
            center: 'Dryer R&D Center',
            patents: 2
          },
          {
            name: 'MEHMET EFELEROĞLU',
            center: 'WAT Motor R&D Center',
            patents: 1
          },
          {
            name: 'FAZIL ERDEM KAYA',
            center: 'Dryer R&D Center',
            patents: 2
          },
          {
            name: 'OZAN YÜZER',
            center: 'Dryer R&D Center',
            patents: 2
          },
          {
            name: 'ŞAHİN SİVASLIOĞLU',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'EMRE YAVUZ TÜRK',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'KEREM KARAHAN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'TAMER CEBECİ',
            center: 'ITU Ari Teknokent R&D Center',
            patents: 1
          },
          {
            name: 'NURİ ACAR',
            center: 'ITU Ari Teknokent R&D Center',
            patents: 1
          },
          {
            name: 'ÖZDEMİR ÖZGEN',
            center: 'ITU Ari Teknokent R&D Center',
            patents: 1
          },
          {
            name: 'MURAT YALÇIN',
            center: 'ITU Ari Teknokent R&D Center',
            patents: 1
          },
          {
            name: 'YUNUS KOÇ',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'MUSTAFA TİRYAKİ',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'YASEMİN ÖZKAN',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'ZEHRA ÜLGER',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ÖZKAN AKGÜL',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ROHAT İBİŞHÜKÇÜ',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'HÜSEYİN CURA',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'SEDA YAYLALI',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'GÖNÜL ÇAVUŞOĞLU',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'EKİN DALAK',
            center: 'Central R&D',
            patents: 4
          },
          {
            name: 'MUHAMMED SERDAR KÜÇÜK',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'MEHMET KERİM PEKER',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'EGE KESKİN',
            center: 'Central R&D',
            patents: 4
          },
          {
            name: 'MELTEM ŞAHİN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'NORMAN LIN',
            center: 'China R&D Center',
            patents: 3
          },
          {
            name: 'LOUIS CHEN',
            center: 'China R&D Center',
            patents: 3
          },
          {
            name: 'UGUR KARAPINAR',
            center: 'Dryer R&D Center',
            patents: 1
          },
          {
            name: 'EMRE KAYACI',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'SERKAN DÜŞMEZ',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'GAVIN SUMMERS',
            center: 'Cambridge R&D Center',
            patents: 1
          },
          {
            name: 'GÖKÇE AKSOY',
            center: 'Washing Machine R&D Center',
            patents: 1
          },
          {
            name: 'HÜSEYİN ÇALIŞKAN',
            center: 'Dishwasher R&D Center',
            patents: 2
          },
          {
            name: 'EMİNE FÜSUN DUMAN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'MERİÇ BERK TURHAN',
            center: 'Central R&D Center',
            patents: 1
          },
          {
            name: 'CELAL VATANSEVER',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ARDA KESLER',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ÖMER FARUK ALTUNER',
            center: 'Dryer R&D Center',
            patents: 1
          },
          {
            name: 'SEFA YASİN UZEN',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'AYCAN GÜRKAŞ',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'ÖZGE BEYAZKILINÇ',
            center: 'Central R&D',
            patents: 3
          },
          {
            name: 'MERYEM GÜNERİ ARIGÜL',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'MELİH TOKLU',
            center: 'Central R&D',
            patents: 5
          },
          {
            name: 'ATİLLA SEVLİ',
            center: 'Washing Machine R&D Center',
            patents: 2
          },
          {
            name: 'SELAHATTİN ÇORAK',
            center: 'Washing Machine R&D Center',
            patents: 1
          },
          {
            name: 'FIRAT YILMAZ',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'İSMAİL YEŞİLAYDIN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'GÖRKEM BEZİRGAN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'EMİNE BİRCİ',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'AYDIN ÇELİK',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'Ramin Fadaei Fouladi',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ALEV FERADOĞLU',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'SAİT FURKAN DEMİR',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'AYBÜKE SÜREK',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'ERTAN SARIBAYRAKDAROĞLU',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'BAHTİYAR YILMAZ',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'TURGUT YERLİKAYA',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'FATİH CEYLAN',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'SİNAN FİDAN',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'MEHMET FATİHHAN KURŞUN',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'TURAN ERDEM ŞİMŞEK',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'ALİ İHSAN İNÇUKUR',
            center: 'Arcelik Design Center',
            patents: 1
          },
          {
            name: 'FETHİ ŞEN',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'ZEYNEP ERENAY',
            center: 'Washing Machine R&D Center',
            patents: 1
          },
          {
            name: 'YONG KI YOON',
            center: 'Washing Machine R&D Center',
            patents: 1
          },
          {
            name: 'AHMET KUBİLAY ŞAVKAN',
            center: 'Washing Machine R&D Center',
            patents: 1
          },
          {
            name: 'SALMAN MUSTAFA HUSAIN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'ALİ BEKİR KILIÇKAYA',
            center: 'Electronics R&D Center',
            patents: 1
          },
          {
            name: 'MEHMET ONUR DİNÇER',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'SELÇUK KARAGÖZ',
            center: 'Dryer R&D Center',
            patents: 2
          },
          {
            name: 'ŞEHMUZ ALİ SUBAY',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 2
          },
          {
            name: 'MEHMET İNAN',
            center: 'Dryer R&D Center',
            patents: 1
          },
          {
            name: 'TUĞÇE USTA',
            center: 'Central R&D',
            patents: 2
          },
          {
            name: 'HAKAN ATEŞ',
            center: 'Dishwasher R&D Center',
            patents: 1
          },
          {
            name: 'İLHAN ÖZGÜ ÖZGÜR',
            center: 'Dishwasher R&D Center',
            patents: 1
          },
          {
            name: 'MİRZA MOHAMMAD MUFLEH AHMAD',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'KUTAY SARIALAN',
            center: 'Cooking Appliances R&D Center',
            patents: 1
          },
          {
            name: 'ARDA KUYUMCU',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'AHMET ALPTEKİN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'DOĞAN DEMİRHAN',
            center: 'Central R&D',
            patents: 1
          },
          {
            name: 'SERKAN TOPALOĞLU',
            center: 'Refrigerator and Compressor R&D Center',
            patents: 1
          },
          {
            name: 'ERKAN EYLEN',
            center: 'Washing Machine R&D Center',
            patents: 1
          },
          {
            name: 'ÖMER ALTAY',
            center: 'Washing Machine R&D Center',
            patents: 1
          },
          {
            name: 'FURKAN ŞAHİN',
            center: 'Dryer R&D Center',
            patents: 1
          },
          {
            name: 'MEHMET KARA',
            center: 'Dryer R&D Center',
            patents: 1
          }
        ],
        'name'
      ),
      design: sortBy(
        [
          {
            name: 'İrem Ezgi Boz',
            center: 'Arçelik Design Center',
            count: 5,
            awards: 'IF DESIGN'
          },
          {
            name: 'Soner Ilgın',
            center: 'Arçelik Design Center',
            count: 10,
            awards: 'IF DESIGN/RED DOT'
          },
          {
            name: 'Tamer Yüksek',
            center: 'Arçelik Design Center',
            count: 2,
            awards: 'IF DESIGN/RED DOT'
          },
          {
            name: 'Özlem Kök Gültekin',
            center: 'Arçelik Design Center',
            count: 2,
            awards: 'IF DESIGN'
          },
          {
            name: 'Ali İhsan İnçukur',
            center: 'Arçelik Design Center',
            count: 5,
            awards: 'IF DESIGN/RED DOT'
          },
          {
            name: 'Duygu Turancı',
            center: 'Arçelik Design Center',
            count: 4,
            awards: 'IF DESIGN/RED DOT'
          },
          {
            name: 'Elif Altay',
            center: 'Arçelik Design Center',
            count: 1,
            awards: 'IF DESIGN'
          },
          {
            name: 'Nur Fındık Önal',
            center: 'Arçelik Design Center',
            count: 4,
            awards: 'IF DESIGN/RED DOT'
          },
          {
            name: 'Gizem Durakoğlu',
            center: 'Arçelik Design Center',
            count: 1,
            awards: 'RED DOT'
          },
          {
            name: 'Mustafa Demirel',
            center: 'Arçelik Design Center',
            count: 2,
            awards: 'RED DOT'
          },
          {
            name: 'Çağatay Akça',
            center: 'Arçelik Design Center',
            count: 8,
            awards: 'IF DESIGN/RED DOT'
          },
          {
            name: 'Baran Tigrel',
            center: 'Arçelik Design Center',
            count: 1,
            awards: 'RED DOT'
          },
          {
            name: 'Denizhan Arda',
            center: 'Arçelik Design Center',
            count: 1,
            awards: 'RED DOT'
          },
          {
            name: 'Nihan Elçin',
            center: 'Arçelik Design Center',
            count: 1,
            awards: 'RED DOT'
          }
        ],
        'name'
      )
    };
  },
  computed: {},
  created() {},
  beforeDestroy() {},
  methods: {}
};
</script>

<style></style>
