<template>
  <v-container class="">
    <v-row class="py-12">
      <v-col cols="12" md="6">
        <v-card
          to="/awards/innovation"
          class="d-flex flex-column flex-1 align-center pa-6 white--text"
          style="width: 100%; background-color: rgba(249, 0, 46, 0.7) !important"
        >
          <div>
            <v-icon class="text-h1 white--text">mdi-seal</v-icon>
          </div>
          <div>
            <h1 class="">
              22nd Innovation Day
            </h1>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card
          to="/awards/reliability"
          class="d-flex flex-column flex-1 align-center pa-6 white--text"
          style="width: 100%; background-color: rgba(249, 0, 46, 0.7) !important"
        >
          <div>
            <v-icon class="text-h1 white--text">mdi-seal</v-icon>
          </div>
          <div>
            <h1 class="">
              5th Reliability Day
            </h1>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Booth',
  data() {
    return {};
  },
  computed: {},
  created() {},
  beforeDestroy() {},
  methods: {}
};
</script>

<style></style>
