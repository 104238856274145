<template>
  <v-container fluid class="fill-height justify-center">
    <div
      class="d-flex flex-row align-center justify-center"
      style="max-width: 550px; width: 100%;"
    >
      <div class="d-flex flex-column" style="width: 100%;">
        <Logo class="mb-10" />
        <LanguageSelector buttons class="mb-5" />
        <OtpForm class="mb-5" />
        <PhoneSupportBox />
      </div>
    </div>
  </v-container>
</template>

<route>
{
  "meta": {
    "guest": true
  }
}
</route>

<script>
// import LanguageSelector from '../components/LanguageSelector';
import PhoneSupportBox from '../components/guest/PhoneSupportBox';
import OtpForm from '../components/guest/OtpForm';
import Logo from '../components/Logo';
import LanguageSelector from '@/components/LanguageSelector';

export default {
  name: 'EnterCode',
  auth: 'guest',
  components: { PhoneSupportBox, OtpForm, Logo, LanguageSelector },
  layout: 'guest',
  head() {
    return {
      title: this.$t('verification.title')
    };
  },
  created() {
    if (window.RocketChat) {
      window.RocketChat(function() {
        this.showWidget();
      });
    }
  }
};
</script>

<style></style>
