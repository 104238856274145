<template>
  <v-container fluid class="ma-0 pa-0">
    <iframe
      class="fullframe"
      allowfullscreen
      allow="fullscreen; autoplay; picture-in-picture; accelerometer; encrypted-media; gyroscope"
      src="https://arcelik-design-awards.kraftend.dev/"
    ></iframe>
  </v-container>
</template>

<script>
export default {
  name: 'Booth',
  data() {
    return {
      showIframe: false
    };
  },
  computed: {},
  created() {},
  beforeDestroy() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.fullframe {
  width: 100%;
  height: calc(100vh - 64px);
  border: none;
  margin: 0;
}
</style>
