<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="10" md="6" lg="4">
        <v-card
          max-width="672px"
          elevation="15"
          class="rounded-lg align-center justify-center"
          style="overflow: hidden; margin: 0 auto; padding: 0; background: black;"
        >
          <div style="padding-top: 166%; position: relative; overflow: hidden;">
            <iframe
              src="https://photobooth.kraftend.dev/d1e91a64-ec59-45cf-937b-bdf45340954e?png=1"
              style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden;"
              frameborder="0"
              allow="camera; fullscreen; picture-in-picture; download"
            ></iframe>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { isMobile } from '@/lib/utils';
export default {
  name: 'Booth',
  data() {
    return {
      showIframe: false
    };
  },
  computed: {},
  created() {},
  beforeDestroy() {},
  methods: {
    handleNext() {
      if (isMobile()) {
        window.open('https://arcelikqhub-photobooth.kraftend.dev/en', '_blank');
      } else {
        this.showIframe = true;
      }
    }
  }
};
</script>

<style></style>
