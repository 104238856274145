<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-title class="pt-8 white--text">
          <h1>
            5th Reliability Day
          </h1>
        </v-card-title>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
            xl="3"
            v-for="p in reliability"
            :key="p.name"
            class="d-flex mb-n5"
          >
            <v-alert
              class="d-flex flex-1"
              icon="mdi-seal"
              prominent
              type="info"
              style="width: 100%; background-color: rgba(249, 0, 46, 0.8) !important"
            >
              <div class="text-body-2">
                {{ p.leader + (!!p.team ? ',' : '') }} {{ p.team }}
              </div>
              <h4 class="">{{ p.center }}</h4>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Booth',
  data() {
    return {
      reliability: [
        {
          center: 'Refrigerator',
          leader: 'Burcu Tava',
          team:
            'Turan Erdem Şimşek,Cihat Bacak,Serra Topçugil,Mehmet Güngör Kul,İhsan Arabayapan'
        },
        {
          center: 'Refrigerator',
          leader: 'Mertcan Özbek',
          team: 'Gürkan Öztop,Tuğba Tosun'
        },
        {
          center: 'Refrigerator',
          leader: 'Tekin Aslan',
          team: 'Dilek Sur, Gökhan Ulutak,Ergin Özkan'
        },
        {
          center: 'Refrigerator',
          leader: 'Elshad Mahmudov',
          team:
            'Gökhan Göl , Ali Babayiğit, Nuri Özbey, Elshad Mahmudov, Burak Şahin, İnci Milci'
        },
        {
          center: 'Washing Machine',
          leader: 'Yakup Aktaş',
          team: 'Halil Atilla, Burak Yoca, Fatih Çığrıkçı'
        },
        {
          center: 'Washing Machine',
          leader: 'Günseli Güç',
          team: 'Burçin Bilici, Akın Ak, Hilal Bat, Ceren Yargıcı'
        },
        {
          center: 'Washing Machine',
          leader: 'Talha Qureshi',
          team: 'Erhan Çelik'
        },
        {
          center: 'Washing Machine',
          leader: 'Gülce Tokyol',
          team: ''
        },
        {
          center: 'Cooking Appliances',
          leader: 'Ahmet Yasin Teker',
          team: 'Cengiz Arslan, Yalçın Yüksel, Sercan Kuşcu, Şahin Dursun'
        },
        {
          center: 'Cooking Appliances',
          leader: 'Mustafa ÖNAL',
          team:
            'Ufuk Sezen Arat, Erdinç Elgin, Engin Beyter, Shouq Al-Shwayyat, Soner Gültekin'
        },
        {
          center: 'Cooking Appliances',
          leader: 'Shouq Al-Shwayyat',
          team:
            'Sevilay Gürbüz, Erdinç Elgin, Soner Gültekin, Murat Eray Bayındır'
        },
        {
          center: 'Cooking Appliances',
          leader: 'Sevilay Gürbüz',
          team: 'Shouq Al-Shwayyat, Erdinç Elgin, Çetin Tekir'
        },
        {
          center: 'Tumble Dryer',
          leader: 'Muhammed Sezgin Öner',
          team:
            'Doğan Taştan, Burak Berk Uz, Ceyhan Işıklı, Mehmet Kara, Selim Mollaoğlu'
        },
        {
          center: 'Tumble Dryer',
          leader: 'Furkan Şahin',
          team: 'Alper Batur, Doğan Taştan'
        },
        {
          center: 'Dishwasher',
          leader: 'Önder Sünetci',
          team:
            'Önder Balioğlu, Önder Sünetci, Ayla Kuran, Uğur Kan, Bülent Küçükcoşkun, Hasan İzmitligil, Ömer Argınhan, Aylin Arinç, Burak Özcan, Hakan Ateş'
        },
        {
          center: 'Dishwasher',
          leader: 'Ömer Argınhan',
          team: 'Ömer Argınhan, Hasan İzmitligil'
        },
        {
          center: 'Dishwasher',
          leader: 'Uğur Kan',
          team: 'Uğur Kan, Önder Sünetci'
        },
        {
          center: 'Dishwasher',
          leader: 'Hüseyin Ceylan',
          team: 'Hüseyin Ceylan, Hüseyin Doğu Korkmaz'
        },
        {
          center: 'Television',
          leader: 'Zülal Tosunoğlu',
          team: 'Metehan Özden'
        },
        {
          center: 'Television',
          leader: 'Uğur Emre Doğru',
          team:
            'Zülal Tosunoğlu, Bülent Söz, Yasin Çelik, Metehan Özden, Yunus Emre Aslan'
        },
        {
          center: 'Product Sourcing',
          leader: 'Çiğdem Ezgi Sever',
          team:
            'Necmi Aygen, Ahu Sürmen, Anıl Türk, Burak Kaya, Caner Yalçınkaya'
        },
        {
          center: 'Product Sourcing',
          leader: 'Ali Mert Şengün',
          team:
            'Nihat Özkan, Relly Zhu, Osman Türkmen, Murat Kafa, Serkan Özkan'
        },
        {
          center: 'Compressor',
          leader: 'Tuğba Çetintürk',
          team: 'Devrim Karadeniz , Erol Çevik'
        },
        {
          center: 'Compressor',
          leader: 'Cansın Korkmaz',
          team: 'İlker Yılmaz , İsmail Sönmez'
        },
        {
          center: 'Compressor',
          leader: 'Ersin Öztaş',
          team: 'Dinçer Genç'
        },
        {
          center: 'WAT Motor',
          leader: 'Burak Efe',
          team: 'Gökhan Mersin'
        },
        {
          center: 'Central R&D',
          leader: 'Hüseyin İÇMEN',
          team: 'Hüseyin İçmen, Mehmet Yiğit, Akın Arı, İsmail Çimen'
        },
        {
          center: 'Central R&D',
          leader: 'Erman KİREMİT',
          team:
            'Erman Kiremit, Ercan Ceyhan, Erhan Çelik, Aykut Doğan, Barış Kılıç'
        },
        {
          center: 'Central R&D',
          leader: 'Burak YOCA',
          team:
            'Burak Yoca, Fatih Çığrıkçı, Hamit Can Dinç, Şaban Avcı, Ali Demir, Mehmet Özmen'
        },
        {
          center: 'Arctic Washing Machine',
          leader: 'Mircea Iancu',
          team: ''
        },
        {
          center: 'Arctic Refrigerator',
          leader: 'Alexandru Sabin Bucur',
          team: 'Andreea-Mihaela Lita, Coman Emil'
        },
        {
          center: 'Beko LLC',
          leader: 'Alexander Krotov',
          team: 'Svetlana Sukacheva, Sergey Zhuravlev, Roman Bocharov'
        },
        {
          center: 'Defy',
          leader: 'Nihmal Ramruthan',
          team: 'Patrick Nkwanyana, Revaan Ramrathen'
        },
        {
          center: 'Dawlance',
          leader: 'Faizan Ahmed Ansari',
          team: 'Imran Khalil Ahmed'
        }
      ]
    };
  },
  computed: {},
  created() {},
  beforeDestroy() {},
  methods: {}
};
</script>

<style></style>
