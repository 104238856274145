<template>
  <v-container fluid class="fill-height justify-center">
    <div class="d-flex flex-row align-center justify-center">
      <div class="d-flex flex-column" style="width: 100%;">
        <Logo class="mb-10" />
        <div style="margin: auto;">
          <v-card class="mx-8 pa-2">
            <FlipCountdown deadline="2021-10-25T07:00:00Z" />
          </v-card>
          <img src="../assets/intro.png" class="intro" />
        </div>
      </div>
    </div>
  </v-container>
</template>
<route>
{
  "redirect": "/stage/main",
  "meta": {
    "guest": true
  }
}
</route>

<script>
import Logo from '@/components/Logo.vue';
import FlipCountdown from '@/components/FlipCountdown.vue';

export default {
  name: 'Index',
  layout: 'guest',
  components: { Logo, FlipCountdown }
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.intro {
  width: 900px;
  height: auto;
  max-width: 100%;
  margin-top: 40px;
}
</style>
